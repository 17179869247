import React from 'react'
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom'

import Login from '../pages/Login'

function LogRoutes(props) {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" exact component={Login} />
                
                <Redirect from="*" to="/login" />
            </Switch>
        </BrowserRouter>
    )
}

export default LogRoutes