import React, { useState } from 'react'
import clsx from 'clsx'

import { Link } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import LayersIcon from '@material-ui/icons/Layers';
import MenuIcon from '@material-ui/icons/Menu';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { logout } from '../../action/UserAction'

import useStyles from '../../styles/global'
import { useTheme } from '@material-ui/core';


function SideBar(props) {

    const classes = useStyles()

    const [open, setOpen] = useState(false);
    const { window } = props;

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setOpen(!open);
    };


    const handleLog = () => {

        localStorage.clear()

        props.logout()
    }

    const drawer = (
        <>

            <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerToggle}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List className="nav-list">
                <ListSubheader inset>{props.user.name.split(' ')[0]}</ListSubheader>

                <ListItem onClick={handleLog} button className="nav-link">
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sair" />
                </ListItem>


            </List>
            <Divider />
            {
                props.user.permission <= 3 &&


                <List className="nav-list">
                    <ListSubheader inset>Gestão Concreta</ListSubheader>
                    {
                        props.user.permission === 1 &&
                        <Link to="/" >
                            <ListItem button className="nav-link">
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Painel" />
                            </ListItem>
                        </Link>
                    }

                    <Link to="/pedidos">
                        <ListItem button className="nav-link">
                            <ListItemIcon>
                                <ShoppingCartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pedidos" />
                        </ListItem>
                    </Link>
                    {
                        props.user.permission === 1 &&
                        <>
                            <Link to="/estoque">
                                    <ListItem button className="nav-link">
                                        <ListItemIcon>
                                            <ListAltIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Estoque" />
                                    </ListItem>
                                </Link>

                            <Link to="/produtos">
                                <ListItem button className="nav-link">
                                    <ListItemIcon>
                                        <BusinessCenterIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Produtos" />
                                </ListItem>
                            </Link>
                        </>
                    }
                </List>
            }
            <Divider />
            {
                (props.user.permission === 1 || props.user.permission === 2 || props.user.permission === 4) &&

                <List className="nav-list">
                    <ListSubheader inset>Administração do Site</ListSubheader>
                    <Link to="/solucoes">
                        <ListItem button className="nav-link">
                            <ListItemIcon>
                                <LayersIcon />
                            </ListItemIcon>
                            <ListItemText primary="Soluções" />
                        </ListItem>

                    </Link>
                    {
                        props.user.permission === 1 &&
                        <Link to="/usuarios">
                            <ListItem button className="nav-link">
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItem>

                        </Link>
                    }

                </List>
            }


        </>
    )

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Concreta Soluções
                    </Typography>

                </Toolbar>
            </AppBar>
            <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={open}
                    onClose={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    {drawer}
                </Drawer>
            </Hidden>

        </>
    )
}

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)