import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
    imageMain: {
        width: 100 + "%",
        height: "auto"
    },
    imageStock: {
        width: 25 + "%",
        height: "auto",
        margin: 2 + "%",
    }
}))

export default useStyles