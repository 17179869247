import React, { useEffect, useRef, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import localStyles from './styles'

const initialSugestions = [
    {
        name: "Laje Treliça Forro Isopor",
        unitPrice: 44.70,
        unitOfMeasurement: "Metros quadrados"
    },
    {
        name: "Laje Treliça Forro Lajota",
        unitPrice: 48.70,
        unitOfMeasurement: "Metros quadrados"
    },
    {
        name: "Laje Treliça Piso Vão até 3,5 metros",
        unitPrice: 54.0,
        unitOfMeasurement: "Metros quadrados"
    },
    {
        name: "Laje Tradicional Forro",
        unitPrice: 43.70,
        unitOfMeasurement: "Metros quadrados"
    },
    {
        name: "Laje Tradicional Piso Vão até 3,5 metros",
        unitPrice: 53.0,
        unitOfMeasurement: "Metros quadrados"
    },
    {
        name: "Cocho para Volumoso",
        unitPrice: 280.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Bebedouro 1,00x0,50 metros",
        unitPrice: 165.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Bebedouro 1,20x0,50 metros",
        unitPrice: 210.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Anel de Poço 1,00x0,50 metros",
        unitPrice: 75.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Tampa de Fossa 1,00x0,50 metros",
        unitPrice: 50.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Anel de Poço 1,20x0,50 metros",
        unitPrice: 115.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Tampa de Fossa 1,20x0,50 metros",
        unitPrice: 80.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Poste Reto 10x10x260 centímentros",
        unitPrice: 34.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Poste Curvo 10x10x300 centímentros",
        unitPrice: 40.00,
        unitOfMeasurement: "Unidades"
    },
    {
        name: "Viga de Porta 10x9",
        unitPrice: 14.00,
        unitOfMeasurement: "Metro"
    },
    {
        name: "Viga de Porta 14x9",
        unitPrice: 18.00,
        unitOfMeasurement: "Metro"
    },
]

function InputAutocomplete(props) {

    const [filter, setFilter] = useState("")
    const [options, setOptions] = useState([])
    const [sugestions, setSugestion] = useState([])
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(false)
    const input = useRef(null)

    const styles = localStyles()

    useEffect(() => {

        if (filter !== "") {

            const newSugestions = options.filter(sugestion => sugestion[`${props.fieldFilter}`].toLowerCase().search(filter.toLowerCase()) !== -1)

            setSugestion(newSugestions);
            setOpen(true)

        } else if (filter === "") {

            setSelected(false)
            setSugestion(initialSugestions)
        }

    }, [filter])

    useEffect(() => {
        getOptions()
    }, [])

    const getOptions = async () => {

        const initialOptions = await props.getOptions()

        setOptions(initialOptions)
    }

    const handleClickItem = (sugestion) => {

        setSelected(true)
        setOpen(false)
        setSugestion([])
        setFilter(sugestion[`${props.fieldFilter}`])
        props.onChange(sugestion)
    }

    return (
        <div className={styles.autocomplete}>
            <FormControl className={styles.formControl} >
                <InputLabel htmlFor={`item`}>Item</InputLabel>
                <Input
                    ref={input}
                    id={`item`}
                    type="text"
                    value={filter}
                    onChange={e => {
                        setSelected(false)
                        setFilter(e.target.value)
                    }}
                    onBlur={() => {

                        setTimeout(() => {

                            setOpen(false)
                        }, 500)
                    }}
                />

            </FormControl>
            <div className={styles.autocompleteItems}>
                {
                    open && sugestions.map((sugestion, index) => {

                        return (
                            <div className={styles.autocompleteItem} key={index} onClick={() => { handleClickItem(sugestion) }} >
                                <strong>{sugestion[`${props.fieldFilter}`]}</strong>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default InputAutocomplete