import React from 'react'
import clsx from 'clsx'
import Container from '@material-ui/core/Container';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    Tooltip,
    BarChart,
    Bar,
    PieChart,
    Pie
} from 'recharts';
import { useTheme } from '@material-ui/core/styles';


import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'
import { Grid, Paper } from '@material-ui/core';


// Generate Sales Data
function createData(date, amount) {
    return { date, amount };
}

function createDataBar(month, currenty, last) {
    return { month, currenty, last };
}

function createDataPie(name, value) {
    return { name, value };
}

const data = [
    createData('01/09', 0),
    createData('03/09', 300),
    createData('06/09', 600),
    createData('09/09', 800),
    createData('12/09', 1500),
    createData('15/09', 2000),
    createData('18/09', 2400),
    createData('21/09', 2400),
    createData('24/09', undefined),
    createData('27/09', undefined),
    createData('30/09', undefined),

];

const dataBar = [
    createDataBar('Jan', 0, 350),
    createDataBar('Fev', 300, 240),
    createDataBar('Mar', 600, 430),
    createDataBar('Abr', 800, 794),
    createDataBar('Mai', 1500, 800),
    createDataBar('Jun', 2000, 850),
    createDataBar('Jul', 2400, 960),
    createDataBar('Ago', 2400, 1020),
    createDataBar('Set', undefined, 1300),
    createDataBar('Out', undefined, 1503),
    createDataBar('Nov', undefined, 1900),
    createDataBar('Dez', undefined, 2300),

];

const data01 = [
    createDataPie('Laje Treliçada', 30),
    createDataPie('Laje Convencional', 60),
    createDataPie('Poste de Cerca', 5),
    createDataPie('Cochos', 5),

]

function Dashboard() {

    const theme = useTheme()

    const classes = useStyles()

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <h1>Painel de Desempenho</h1>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={6} lg={6}>

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                                
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper className={fixedHeightPaper}>
                                <h3>Faturamento este mês</h3>
                                <ResponsiveContainer>
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 16,
                                            right: 16,
                                            bottom: 0,
                                            left: 24
                                        }}
                                    >
                                        <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
                                        <YAxis stroke={theme.palette.text.secondary}>
                                            <Label
                                                angle={270}
                                                position="left"
                                                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                                            >
                                                Vendas (R$)
                                            </Label>
                                        </YAxis>
                                        <Tooltip />
                                        <Line type="monotone" name="Quantidade" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Paper className={fixedHeightPaper}>
                                <h3>Lucro Bruto este mês</h3>
                                <ResponsiveContainer>
                                    <LineChart
                                        data={data}
                                        margin={{
                                            top: 16,
                                            right: 16,
                                            bottom: 0,
                                            left: 24
                                        }}
                                    >
                                        <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
                                        <YAxis stroke={theme.palette.text.secondary}>
                                            <Label
                                                angle={270}
                                                position="left"
                                                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                                            >
                                                Ordens de Venda (Un)
                                            </Label>
                                        </YAxis>
                                        <Tooltip />
                                        <Line type="monotone" name="Quantidade" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <Paper className={fixedHeightPaper}>
                                <h3>Faturamento desse ano em relação ao anterior</h3>
                                <ResponsiveContainer>
                                    <BarChart
                                        data={dataBar}
                                        margin={{
                                            top: 16,
                                            right: 16,
                                            bottom: 0,
                                            left: 24
                                        }}
                                    >

                                        <XAxis dataKey="month" stroke={theme.palette.text.secondary} />
                                        <YAxis stroke={theme.palette.text.secondary}>
                                            <Label
                                                angle={270}
                                                position="left"
                                                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                                            >
                                                Faturamento (R$)
                                            </Label>
                                        </YAxis>
                                        <Tooltip />
                                        <Bar dataKey="currenty" name="Este ano" fill="#8884d8" />
                                        <Bar dataKey="last" name="Ano anterior" fill="#82ca9d" />
                                    </BarChart>

                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={6}>

                            <Paper className={fixedHeightPaper}>
                                <h3>Proporção sobre o faturamento</h3>
                                <ResponsiveContainer>
                                    <PieChart  >
                                        <Tooltip />
                                        <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#8884d8" />
                                    </PieChart>

                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

export default Dashboard