import React from 'react'
import MUIDataTable from "mui-datatables";

function MaterialTable(props) {

    const { title, data, columns, options } = props

    return (
        <MUIDataTable
            title={title}
            data={data}
            columns={columns}
            options={{
                selectableRowsHeader: false,
                selectableRows: "none",
                download: false,
                
                textLabels: {
                    filter: {
                        all: "Todos",
                        reset: "Limpar",
                        title: "Filtro",
                    },
                    body: {
                        toolTip: "Ordenar",
                        noMatch: "Nenhum dado encontrado"
                    },
                    pagination: {
                        rowsPerPage: "Linhas por página",
                        displayRows: "de"
                    },
                    toolbar: {
                        filterTable: "Filtro",
                        print: "Imprimir",
                        search: "Pesquisar",
                        viewColumns: "Visualizar Colunas"
                    },
                    viewColumns: {
                        title: "Visualizar Colunas"
                    },

                },
                ...options
            }}
        />
    )

}

export default MaterialTable