import { makeStyles } from '@material-ui/core/styles'
import { green, red , blue} from '@material-ui/core/colors'

const styles = makeStyles((theme) => ({
    contentButtons: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
    },
    buttonOrder: {
        width: 98 + '%',
        height: 240,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "red",
        fontSize: 32,
        padding: 16,
    },
    buttonOrderSale: {
        background: green[600],
        color: theme.palette.getContrastText(green[600]),
        '&:hover': {
            background: green[700],
        }
    },
    buttonOrderBuy: {
        background: red[600],
        color: theme.palette.getContrastText(red[600]),
        '&:hover': {
            background: red[700],
        }
    },
    formControl: {
        width: 100 + '%'
    },
    table:{
        width: 100 + '%'
    }
    ,
    contentFooter:{
        display:"flex",
        justifyContent:"flex-end"
    },
    printAll:{
        background: blue[700],
        color: theme.palette.getContrastText(blue[700]),
        '&:hover': {
            background: blue[800],
        }
    }
}))

export default styles