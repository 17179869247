import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import localStyles from './styles'

function FormCliente() {

    const [typeClient, setTypeClient] = useState('PF')

    const styles = localStyles()

    return (
        <>
            <Grid container spacing={3} className={styles.contentClient}>
                <Grid item xs={12} md={12} lg={12}>
                    <FormControl className={styles.formControl}>
                        <InputLabel id={`type-of-client-label`} >Tipo de Cadastro</InputLabel>

                        <Select
                            labelId={`type-of-client-label`}
                            id={`type-of-client`}
                            value={typeClient}
                            onChange={event => setTypeClient(event.target.value)}
                        >
                            < MenuItem value={"PF"}>Pessoa Física</MenuItem>
                            < MenuItem value={"PJ"}>Pessoa Jurídica</MenuItem>


                        </Select>

                    </FormControl>
                </Grid>
                {
                    typeClient === 'PJ' &&
                    <>

                        <Grid item xs={12} md={12} lg={12}>

                            <h3>Dados da Empresa</h3>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>

                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`social-reason-of-client`}>Razão Social</InputLabel>
                                <Input
                                    style={{ color: "#333" }}
                                    id={`social-reason-of-client`}
                                />

                            </FormControl>

                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>

                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`fantasy-name-of-client`}>Nome Fantasia</InputLabel>
                                <Input
                                    style={{ color: "#333" }}
                                    id={`fantasy-name-of-client`}
                                />

                            </FormControl>

                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>

                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`document-business-of-client`}>CNPJ</InputLabel>
                                <Input
                                    style={{ color: "#333" }}
                                    id={`document-business-of-client`}
                                />

                            </FormControl>

                        </Grid>
                                               
                        
                    </>
                }

                <Grid item xs={12} md={12} lg={12}>

                    <h3>Dados Pessoais</h3>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>

                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`nome-of-client`}>Nome completo *</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`name-of-client`}
                        />

                    </FormControl>

                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`email-of-client`}>E-mail</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`email-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>

                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`document-of-client`}>CPF</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`document-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`birth-of-client`}>Data de Nascimento</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`birth-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`phone-of-client`}>Telefone</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`phone-of-client`}
                        />

                    </FormControl>
                </Grid>
                
                
                <Grid item xs={12} md={12} lg={12}>
                    <h3>Endereço Pessoal</h3>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`cep-of-client`}>CEP</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`cep-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`city-of-client`}>Cidade</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`city-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`state-of-client`}>Estado</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`state-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`street-of-client`}>Rua</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`street-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`number-of-client`}>Número</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`number-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`neiborhood-of-client`}>Bairro</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`neiborhood-of-client`}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <FormControl className={styles.formControl} >
                        <InputLabel htmlFor={`complement-of-client`}>Complemento</InputLabel>
                        <Input
                            style={{ color: "#333" }}
                            id={`complement-of-client`}
                        />

                    </FormControl>
                </Grid>

            </Grid>
            <Grid container justify="flex-end" style={{ marginTop: 16, marginBottom: 16 }}  >
                <Button className={styles.buttonCreateClient} >
                    Adicionar cliente
                </Button>
            </Grid>
        </>
    )
}

export default FormCliente