import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'

import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'
import api from '../../service/api';
import Swal from 'sweetalert2';


function ShowUsuario(props) {

    const classes = useStyles()

    const history = useHistory()

    const [user, setUser] = useState(null)

    useEffect(() => {
        if (user === null)
            getUser()
    })

    const getUser = async () => {


        const { id } = props.match.params

        const { token } = props.user

        api.get(`/usuario/${id}`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {

            setUser(res.data)

        }).catch(err => {
            console.error(err)
        })
    }

    const renderPermission = (permission) => {

        switch (permission) {
            case 1:
                return "Administrador"
            case 2:
                return "Funcionário / Site"
            case 3:
                return "Funcionário"
            case 4:
                return "Site"
            default:
                return "Erro na permissão"
        }
    }

    const deleteUser = async () => {

        Swal.fire({
            title: 'Apagar',
            text: 'Essa operação será irrevesível, realmente deseja apagar este item?',
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Cancelar",
            denyButtonText: `Apagar`,
            icon: 'info'
        }).then((result) => {

            if (result.isDenied) {

                const { id } = props.match.params

                const { token } = props.user


                api.delete(`/usuario/${id}`, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }).then(res => {

                    Swal.fire({
                        position: 'bottom-end',
                        icon: 'success',
                        title: 'Usuário Apagado!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    history.goBack()

                }).catch(err => {
                    console.error(err)
                })
            }
        })


    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        <h1>Décio da Silva Santos</h1>
                    </Grid>
                    <Grid container className={classes.contentItem}>
                        <Grid item xs={12} md={12} lg={12}>
                            <h3>Dados do Usuário</h3>
                            <h4>Nome Completo</h4>
                            <p>{user && user.name}</p>
                            <h4>E-mail</h4>
                            <p>{user && user.email}</p>
                            <h4>Permissão</h4>
                            <p>{user && renderPermission(user.permission)}</p>
                        </Grid>

                        <Grid container spacing={3} direction="row" justify="flex-end" style={{ padding: '3rem' }}>
                            <Button onClick={() => { history.goBack() }} className={classes.buttonSecondary} style={{ marginRight: "1rem" }}>
                                Voltar
                            </Button>
                            {
                                user &&
                                <>
                                    <Button onClick={() => { deleteUser() }} className={classes.buttonDanger} style={{ marginRight: "1rem" }}>
                                        Apagar
                                    </Button>
                                    <Link to={`/usuarios/edit/${user._id}`}>
                                        <Button className={classes.buttonWarning} style={{ marginRight: "1rem" }}>
                                            Editar
                                        </Button>
                                    </Link>

                                </>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    )
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(ShowUsuario)