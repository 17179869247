const INITIAL_STATE = {
    _id: '',
    name: '',
    token: '',
    permission: 0,
    logged: false
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case 'LOGIN':
            return { ...state, ...action.payload }

        case 'LOGOUT':
            return { ...INITIAL_STATE }

        default:
            return INITIAL_STATE
    }
}
