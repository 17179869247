import { makeStyles } from '@material-ui/core/styles';
import { grey, red } from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: "center",
        justifyContent: 'center',
        margin: 0,
    },
    card: {
        borderRadius: 8,
        background: grey[100],
        padding: 24,
        maxWidth: 500
    },
    cardHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row"
    },
    cardTitle: {
        marginLeft: 8
    },
    title: {
        lineHeight: "1rem",
    },
    subtitle: {
        lineHeight: "1rem",

    },
    cardFooter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
        marginTop: 32
    },
    buttonMissed: {
        outline: 'none',
        border: 'none',
        cursor: "pointer",
        textDecorationLine: 'underline',
        background: "transparent",
        '& :hover': {

        }
    }
}));


export default useStyles