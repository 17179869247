import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import SideBar from '../../components/SideBar';
import NumberFormatReal from '../../components/NumberFormatReal'
import NumberFormatPercent from '../../components/NumberFormatPercent'

import useStyles from '../../styles/global'

import localStyles from '../CreateVenda/styles'

function CreateCompra() {

    const classes = useStyles()

    const styles = localStyles()

    return (
        <div className={classes.root}>
            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <h1>Ordem de Compra</h1>
                    <Grid container spacing={3} direction="row" justify="center" alignItems="center" className={styles.contentItem}>
                        <Grid item xs={12} md={5} lg={5}>
                            <FormControl className={styles.formControl}>
                                
                                <TextField
                                    id="name-item"
                                    label="Item *"
                                    
                                    multiline
                                />

                            </FormControl>
                            <FormControl className={styles.formControl}>
                                <TextField
                                    id="description-item"
                                    label="Descrição (opcional)"
                                    
                                    multiline
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5}>
                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`value-amount`}>Quantidade *</InputLabel>
                                <Input

                                    id={`value-amount`}
                                    type="number"
                                />

                            </FormControl>
                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`value-uni`}>Valor Unitário (R$) *</InputLabel>
                                <Input

                                   
                                    id={`value-uni`}
                                    inputComponent={NumberFormatReal}
                                />

                            </FormControl>

                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`value-total`}>Valor Total (R$)</InputLabel>
                                <Input
                                    style={{ color: "#333" }}
                                    
                                    disabled

                                    id={`value-total`}
                                    inputComponent={NumberFormatReal}
                                />

                            </FormControl>

                        </Grid>

                    </Grid>
                </Container>
            </main>
        </div>
    )
}

export default CreateCompra