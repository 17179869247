import { green, grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
    contentClient: {
        borderColor: grey[600],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        padding: 16,
        marginTop: 24,
        boxSizing: "border-box"
    },
    formControl: {
        width: 100 + '%',
        marginTop: 8
    },
    buttonCreateClient:{
        marginTop: 8,
        background: green[600],
        color: theme.palette.getContrastText(green[600]),
        '&:hover':{
            background: green[800]
        }
    }
}))

export default useStyles