import { green, grey } from "@material-ui/core/colors";

const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
    contentItem: {
        borderColor: grey[600],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        padding: 16,
        marginTop: 24
    },
    formControl: {
        width: 90 + '%',
        marginTop: 8
    },
    inputAmount: {
        width: 50 + '%',
        marginTop: 8
    },
    unitOfMeasurement: {
        display: "flex",
        alignItems: "center"
    },
    formInstallment:{
        display: "flex",
        flexDirection: "row",
        alignItems:"center",
        marginTop: 8
    },
    formValueInstallment:{
        width:  30 + "%",
        marginTop: 8,
        marginLeft: 16
    },
    contentButtonAddField: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    contentTotal: {
        background: grey[400],
        color: theme.palette.getContrastText(grey[400]),
        borderRadius: 8,
        padding: 16,
        marginTop: 24
    },
    contentClient: {
        borderColor: grey[600],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        padding: 16,
        marginTop: 16
    },
    buttonFinishOrder: {
        background: green[700],
        color: theme.palette.getContrastText(green[700]),
        '&:hover': {
            background: green[800],
        }
    },
    searchForm: {
        width: 100 + '%',
        marginBottom: 8
    },
    contentTitle: {
        marginTop: 8
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    contentForm: {
        maxWidth: "90vw",
        background: "#ffffff",
        height: "85vh",
        borderRadius: 16,
        marginTop: "5vh",
        overflowY: "scroll",
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
    }
}))

export default useStyles