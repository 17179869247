import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';

import SideBar from '../../components/SideBar'
import TableData from '../../components/TableData';


import useStyles from '../../styles/global'
import api from '../../service/api';

function Solucoes() {

    const classes = useStyles()

    const [solucoes, setSolucoes] = useState([])

    useEffect(() => {
        getSolucoes()
    },[])

    const getSolucoes = async () => {

        api
            .get('/solucao')
            .then(res => {

                if(res.data){
                    setSolucoes(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        <h1>Soluções do Site</h1>
                        <Link to="/solucoes/novo" >

                            <Button >
                                <AddBoxIcon /> {"  "}Cadastrar Solução
                            </Button>
                        </Link>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} >
                            <TableData
                                data={solucoes}

                                columns={[
                                    {
                                        name: 'title',
                                        label: "Nome",
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'description',
                                        label: 'Descrição',
                                        options: {
                                            filter: false,
                                            sort: true,
                                            customBodyRender: (value) => `${value.substr(0, 60)}...`

                                        }
                                    },
                                    {
                                        name: 'action',
                                        label: 'Ações',
                                        options: {
                                            filter: false,
                                            sort: false,
                                            customBodyRender: (value, tableMeta) => {
                                                
                                                return (
                                                    <div>
                                                        <Link to={`/solucao/${tableMeta.tableData[tableMeta.rowIndex]._id}`}>
                                                            <Button>
                                                                <SearchIcon />
                                                            </Button>
                                                        </Link>

                                                    </div>
                                                )
                                            },
                                            print: false
                                        }
                                    }
                                ]}
                                title="Soluções"
                                options={{
                                    filter: false,
                                    print: false
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

export default Solucoes