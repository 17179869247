import React from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import FormCliente from '../../components/FormCliente'



import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'



function CreateCliente() {

    const classes = useStyles()

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container  >
                        <h1>Cadastrar cliente</h1>

                    </Grid>
                    <FormCliente />
                </Container>
            </main>
        </div>
    )
}

export default CreateCliente