import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'

import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'
import api from '../../service/api';
import Swal from 'sweetalert2';


function ShowProduto(props) {

    const classes = useStyles()

    const history = useHistory()

    const [product, setProduct] = useState(null)

    useEffect(() => {
        if (product === null)
            getProduto()
    })

    const getProduto = async () => {


        const { id } = props.match.params

        const { token } = props.user

        api.get(`/produto/${id}`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {

            setProduct(res.data)

        }).catch(err => {
            console.error(err)
        })
    }


    const deleteUser = async () => {

        Swal.fire({
            title: 'Apagar',
            text: 'Essa operação será irrevesível, realmente deseja apagar este item?',
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Cancelar",
            denyButtonText: `Apagar`,
            icon: 'info'
        }).then((result) => {

            if (result.isDenied) {

                const { id } = props.match.params

                const { token } = props.user


                api.delete(`/produto/${id}`, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }).then(res => {

                    Swal.fire({
                        position: 'bottom-end',
                        icon: 'success',
                        title: 'Produto Apagado!',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    history.goBack()

                }).catch(err => {
                    console.error(err)
                })
            }
        })


    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>

                    <Grid container className={classes.contentItem}>
                        <Grid item xs={12} md={12} lg={12}>
                            <h3>Dados do Produto</h3>
                            <h4>Nome </h4>
                            <p>{product && product.name}</p>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <h4>Unidade de Medida</h4>
                            <p>{product && product.unitOfMeasurement}</p>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <h4>Preço por {product && product.unitOfMeasurement}</h4>
                            <p>{product && `R$ ${product.unitPrice}`}</p>
                        </Grid>

                        <Grid container spacing={3} direction="row" justify="flex-end" style={{ padding: '3rem' }}>
                            <Button onClick={() => { history.goBack() }} className={classes.buttonSecondary} style={{ marginRight: "1rem" }}>
                                Voltar
                            </Button>
                            {
                                product &&
                                <>
                                    <Button onClick={() => { deleteUser() }} className={classes.buttonDanger} style={{ marginRight: "1rem" }}>
                                        Apagar
                                    </Button>
                                    <Link to={`/produto/edit/${product._id}`}>
                                        <Button className={classes.buttonWarning} style={{ marginRight: "1rem" }}>
                                            Editar
                                        </Button>
                                    </Link>

                                </>
                            }
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    )
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(ShowProduto)