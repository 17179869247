import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'

import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'
import api from '../../service/api';
import Swal from 'sweetalert2';


const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required("O nome é obrigatório"),
    email: yup
        .string()
        .email()
        .required("O e-mail é obrigatório"),
    password: yup
        .string()
        .required("A senha é obrigatória"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], "As senhas devem ser iguais")
        .required('A confirmação da senha é obrigatória'),
    permission: yup
        .number()
        .required("A permição é obrigatória")

})

function EditUsuario(props) {

    const classes = useStyles()

    const history = useHistory()

    const [user, setUser] = useState(null)

    useEffect(() => {
        if (user === null)
            getUser()
    })

    const getUser = async () => {


        const { id } = props.match.params

        const { token } = props.user

        api.get(`/usuario/${id}`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {

            
            setUser(res.data)

        }).catch(err => {
            console.error(err)
        })
    }

    const editUser = async (values) => {

        const { token } = props.user

        api.put(`/usuario/${user._id}`, values,{
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {
            if(res.data){

                Swal.fire({
                    position: 'bottom-end',
                    icon: 'success',
                    title: 'Usuário Atualizado',
                    showConfirmButton: false,
                    timer: 1500
                })

                history.goBack()
            }
        }).catch(err => {

            console.error(err)

        })
        
    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        <h1>Editando Usuário</h1>
                    </Grid>
                    <Grid container className={classes.contentItem}>
                        {user &&

                            <Formik
                                initialValues={{
                                    name: user.name,
                                    email: user.email,
                                    permission: user.permission,
                                    password: '',
                                    confirmPassword: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    editUser(values)
                                }}
                            >
                                {({ values, handleChange, handleSubmit, errors, touched, setFieldTouched }) => (
                                    <>
                                        <Grid container spacing={3} style={{ padding: '2rem' }} >


                                            <h3>Dados do Usuário</h3>
                                            <Grid item xs={12} md={12} lg={12} >

                                                <FormControl className={classes.formControl} >
                                                    <InputLabel htmlFor={`name-of-user`}>Nome do Usuário *</InputLabel>
                                                    <Input
                                                        style={{ color: "#333" }}
                                                        id={`name-of-user`}
                                                        value={values.name}
                                                        name="name"
                                                        onChange={handleChange}
                                                        onBlur={() => setFieldTouched('name')}
                                                    />
                                                    {
                                                        touched.name && errors.name &&
                                                        <p style={{ color: 'red' }}>{errors.name}</p>
                                                    }
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>

                                                <FormControl className={classes.formControl} >
                                                    <InputLabel htmlFor={`email-of-user`}>E-mail do Usuário *</InputLabel>
                                                    <Input
                                                        style={{ color: "#333" }}
                                                        id={`email-of-user`}
                                                        type='email'
                                                        value={values.email}
                                                        name="email"
                                                        onChange={handleChange}
                                                        onBlur={() => setFieldTouched('email')}
                                                    />
                                                    {
                                                        touched.email && errors.email &&
                                                        <p style={{ color: 'red' }}>{errors.email}</p>
                                                    }

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel id={`permission-label`} >Permissão *</InputLabel>

                                                    <Select
                                                        labelId={`permission-label`}
                                                        id={`permission`}
                                                        value={values.permission}
                                                        name="permission"
                                                        onChange={handleChange}
                                                        onBlur={() => setFieldTouched('permission')}
                                                    >
                                                        < MenuItem value={4}>Site</MenuItem>
                                                        < MenuItem value={3}>Funcionário</MenuItem>
                                                        < MenuItem value={2}>Funcionário / Site</MenuItem>
                                                        < MenuItem value={1}>Administrador</MenuItem>

                                                    </Select>
                                                    {
                                                        touched.permission && errors.permission &&
                                                        <p style={{ color: 'red' }}>{errors.permission}</p>
                                                    }

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>

                                                <FormControl className={classes.formControl} >
                                                    <InputLabel htmlFor={`password`}>Senha *</InputLabel>
                                                    <Input
                                                        style={{ color: "#333" }}
                                                        id={`password`}
                                                        type="password"
                                                        value={values.password}
                                                        name="password"
                                                        onChange={handleChange}
                                                        onBlur={() => setFieldTouched('password')}
                                                    />
                                                    {
                                                        touched.password && errors.password &&
                                                        <p style={{ color: 'red' }}>{errors.password}</p>
                                                    }

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12}>

                                                <FormControl className={classes.formControl} >
                                                    <InputLabel htmlFor={`confirm-password`}>Confirmar senha *</InputLabel>
                                                    <Input
                                                        style={{ color: "#333" }}
                                                        id={`confirm-password`}
                                                        type="password"
                                                        value={values.confirmPassword}
                                                        name="confirmPassword"
                                                        onChange={handleChange}
                                                        onBlur={() => setFieldTouched('confirmPassword')}
                                                    />
                                                    {
                                                        touched.confirmPassword && errors.confirmPassword &&
                                                        <p style={{ color: 'red' }}>{errors.confirmPassword}</p>
                                                    }

                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3} direction="row" justify="flex-end" style={{ padding: '3rem' }} >
                                            <Button onClick={() => { history.goBack() }} className={classes.buttonDanger} style={{ marginRight: "1rem" }}>
                                                Descartar alterções
                                        </Button>
                                            <Button onClick={handleSubmit} className={classes.buttonSuccess} style={{ marginRight: "1rem" }}>
                                                Salvar alterações
                                        </Button>
                                        </Grid>
                                    </>
                                )}
                            </Formik>
                        }

                    </Grid>
                </Container>
            </main>
        </div>
    )
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(EditUsuario)