import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TableCell from '@material-ui/core/TableCell';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';

import SideBar from '../../components/SideBar'
import TableData from '../../components/TableData';


import useStyles from '../../styles/global'
import api from '../../service/api';
import { connect } from 'react-redux';

function Produtos(props) {

    const classes = useStyles()
    const [products, setProducts] = useState([])

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = async () => {

        const { token } = props.user

        api.get('/produto', {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {

            if (res.data) {
                setProducts(res.data)
            }
        })
            .catch(err => {

                console.error(err)
            })


    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        <h1>Produtos</h1>
                        <Link to="/produtos/novo" >

                            <Button >
                                <AddBoxIcon />
                                Adicionar Produto
                            </Button>
                        </Link>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} >
                            <TableData
                                data={products}
                                columns={[
                                    {
                                        name: 'name',
                                        label: "Nome",
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'unitPrice',
                                        label: 'Preço/Uni',
                                        options: {
                                            filter: false,
                                            sort: true,
                                            customBodyRender: (value) => `R$ ${value}`
                                        }
                                    },
                                    {
                                        name: 'unitOfMeasurement',
                                        label: 'Un. de Medida',
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'action',
                                        label: 'Ações',
                                        options: {
                                            filter: false,
                                            sort: false,
                                            customBodyRender: (value, tableMeta) => (
                                                <div>
                                                    <Link to={`/produto/${tableMeta.tableData[tableMeta.rowIndex]._id}`}>
                                                        <Button>
                                                            <SearchIcon />
                                                        </Button>
                                                    </Link >

                                                </div>
                                            ),
                                            print: false
                                        }
                                    }
                                ]}
                                title="Produtos"
                                options={{
                                    filter: false
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

const mapStateToProps = state => ({ user: state.user })


export default connect(mapStateToProps)(Produtos)