
function login(user) {

    return {
        type: "LOGIN",
        payload: {
            _id: user._id,
            name: user.name,
            token: user.token,
            permission: user.permission,
            logged: true
        }
    }
}

function logout() {
    return {
        type: "LOGOUT",
        payload: {
            _id: '',
            name: '',
            token: '',
            permission: 0,
            logged: false
        }
    }
}

export {
    login,
    logout
}