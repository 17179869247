import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';

import SideBar from '../../components/SideBar'
import TableData from '../../components/TableData';


import useStyles from '../../styles/global'
import api from '../../service/api';

function Usuarios() {

    const classes = useStyles()

    const [usuarios, setUsuarios] = useState([])

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {

        api
            .get('/usuario', {
                headers: {
                    Authorization: `Beare `
                }
            })
            .then(res => {
                if (res.data)
                    setUsuarios(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        <h1>Usuários do Site</h1>
                        <Link to="/usuarios/novo" >

                            <Button >
                                <AddBoxIcon /> {"  "}Cadastrar Usuário
                            </Button>
                        </Link>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} >
                            <TableData
                                data={usuarios}

                                columns={[
                                    {
                                        name: 'name',
                                        label: "Nome",
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'email',
                                        label: "E-mail",
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'action',
                                        label: 'Ações',
                                        options: {
                                            filter: false,
                                            sort: false,
                                            customBodyRender: (value, tableMeta) => {

                                                return (
                                                    <div>
                                                        <Link to={`/usuario/${tableMeta.tableData[tableMeta.rowIndex]._id}`}>
                                                            <Button>
                                                                <SearchIcon />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                )
                                            },
                                            print: false
                                        }
                                    }
                                ]}
                                title="Usuários"
                                options={{
                                    filter: false,
                                    print: false
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </div>
    );
}

export default Usuarios