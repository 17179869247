
const { makeStyles } = require("@material-ui/core")

const useStyles = makeStyles((theme) => ({

    formControl: {
        width: 90 + '%',
        marginTop: 8
    },
    autocomplete: {
        position: "relative",
        display: "inline-block",
        width: 100 + "%"
    },
    autocompleteItems: {
        position: "absolute",
        border: "1px solid #d4d4d4",
        borderBottom: "none",
        borderTop: "none",
        zIndex: 99,
        width:90 + "%",
        top: "100%",
        left: 0,
        right: 0,
        maxHeight: 300 + 'px',
        overflowY: "scroll"
    },
    autocompleteItem: {
        padding: "10px",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d4d4d4",
        "&:hover": {
            backgroundColor: "#e9e9e9",
        }
    },

    autocompleteActive: {
        backgroundColor: "DodgerBlue !important",
        color: "#ffffff",
    }
}))

export default useStyles