import React from 'react'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { Formik } from 'formik'
import * as yup from 'yup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import useStyles from '../../styles/global'
import localStyles from './styles'

import { Button } from '@material-ui/core';

import { login } from '../../action/UserAction'
import api from '../../service/api';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required("O e-mail é obrigatório"),
    password: yup
        .string()
        .required("A senha é obrigatória")
})

function Login(props) {

    const classes = useStyles()

    const styles = localStyles()


    const loginUser = async (values) => {

        api.post('/login', values)
            .then(res => {

                if (res.data.error) {
                    Swal.fire(
                        'Erro',
                        `${res.data.error}!`,
                        "error"
                    )
                } else {

                    const { user, token } = res.data

                    user.token = token

                    props.login(user)

                    localStorage.setItem('token', token)

                    Swal.fire({
                        position: 'bottom-end',
                        icon: 'success',
                        title: 'Seja bem-vindo',
                        showConfirmButton: false,
                        text: user.name,
                        timer: 1500
                    })

                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (

        <div className={styles.container}>

            <Grid item xs={12} md={4} lg={4} className={styles.card}>
                <div className={styles.cardHeader}>
                    <img
                        alt="Concreta"
                        src="/logo4.png"
                        width="100"
                        height="100"
                        className={styles.cardImage}
                    />{' '}
                    <div className={styles.cardTitle}>
                        <h1 className={styles.title}>Concreta </h1>
                        <p className={styles.subtitle}>Fabrica de Lajes</p>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        loginUser(values)
                    }}
                >
                    {({ values, handleChange, handleSubmit, errors, touched, setFieldTouched }) => (

                        <Grid item xs={12} md={12} lg={12}>
                            <form onSubmit={e => {
                                e.preventDefault()

                                handleSubmit()
                            }}>

                                <FormControl className={classes.formControl} style={{ marginTop: 24 }} >
                                    <InputLabel htmlFor={`email-of-user`}>E-mail</InputLabel>
                                    <Input
                                        style={{ color: "#333" }}
                                        id={`email-of-user`}
                                        type='email'
                                        value={values.email}
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={() => setFieldTouched('email')}
                                    />
                                    {
                                        touched.email && errors.email &&
                                        <p style={{ color: 'red' }}>{errors.email}</p>
                                    }

                                </FormControl>
                                <FormControl className={classes.formControl} >
                                    <InputLabel htmlFor={`email-of-user`}>Senha</InputLabel>
                                    <Input

                                        style={{ color: "#333" }}
                                        id={`email-of-user`}
                                        type='password'
                                        value={values.password}
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={() => setFieldTouched('password')}
                                    />
                                    {
                                        touched.password && errors.password &&
                                        <p style={{ color: 'red' }}>{errors.password}</p>
                                    }

                                </FormControl>
                                <div className={styles.cardFooter}>
                                    <p className={styles.buttonMissed}>
                                        Esqueceu a senha?
                                </p>

                                    <Button type="submit" className={classes.buttonSuccess} >
                                        Entrar
                                </Button>
                                </div>

                            </form>
                        </Grid>
                    )}

                </Formik>
            </Grid>
        </div>


    )
}

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)