import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Swal from 'sweetalert2'

import Routes from './routes/AppRoutes'
import Login from './routes/LogRoutes'

import { login } from './action/UserAction'

import api from './service/api';

import './styles/global.css'

function App(props) {

  useEffect(() => {
    checkToken()
  }, [])

  const checkToken = async () => {

    const token = localStorage.getItem('token')

    if (token) {

      // Verificar o token
      api.get('/auth', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          if (!res.data.error) {

            const user = res.data

            user.token = token


            props.login(user)

          }
        })
        .catch(err => {
          console.log(err)
        })

    }

  }

  if (!props.user.logged)
    return <Login />

  return (
    <Routes />
  );
}

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(App);