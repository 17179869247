import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';


import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'

import orderStyles from './styles'

import TableData from '../../components/TableData';

function createDataTable(items, value, status, date) {

    return {
        items,
        value,
        status,
        date
    }
}

const data = [
    createDataTable('Lajes Treliçada', 400, "PENDENTE", '20/08/2020'),
    createDataTable('Lajes Treliçada', 300, "PENDENTE", '19/08/2020'),
    createDataTable('Cimento', 1200, "PENDENTE", '16/08/2020'),
    createDataTable('Areia', 400, "PENDENTE", '16/08/2020'),
    createDataTable('Lajes Treliçada', 400, "PENDENTE", '16/08/2020'),
    createDataTable('Lajes Convenciona1', 300, "PENDENTE", '16/08/2020'),
    createDataTable('Lajes Treliçada', 700, "PENDENTE", '16/08/2020'),
    createDataTable('Lajes Treliçada', 300, "PENDENTE", '15/08/2020'),
    createDataTable('Lajes Treliçada', 1200, "PENDENTE", '15/08/2020')

]

function Pedidos() {

    const classes = useStyles()

    const styles = orderStyles()

    const [order, setOrder] = useState('Todos')
    const [ofTheDate, setOfTheDate] = useState('2020-09-01')
    const [untilTheDate, setUntilTheDate] = useState('2020-09-17')

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <h1>Pedidos</h1>
                    <Grid container spacing={3} className={styles.contentButtons}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Link to="/pedido/novo" >
                                <Button className={clsx(styles.buttonOrder, styles.buttonOrderSale)} >
                                    <AddBoxIcon style={{ fontSize: 80 }} />
                                    Criar novo pedido
                                </Button>
                            </Link>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3} style={{ marginTop: 2 + 'rem' }}>


                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="outlined" className={styles.formControl}>
                                <TextField
                                    id="of-the-date"
                                    label="De"
                                    variant="outlined"
                                    type="date"
                                    value={ofTheDate}
                                    onChange={(event) => setOfTheDate(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl variant="outlined" className={styles.formControl}>
                                <TextField
                                    id="until-the-date"
                                    label="Até"
                                    variant="outlined"
                                    type="date"
                                    value={untilTheDate}
                                    onChange={(event) => setUntilTheDate(event.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TableData
                                data={data}
                                columns={[
                                    {
                                        name: 'items',
                                        label: "Items",
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'value',
                                        label: 'Valor (R$)',
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },

                                    {
                                        name: 'date',
                                        label: 'Data',
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'status',
                                        label: 'Pagamento',
                                        options: {
                                            filter: false,
                                            sort: true
                                        }
                                    },
                                    {
                                        name: 'action',
                                        label: 'Ações',
                                        options: {
                                            filter: false,
                                            sort: false,
                                            customBodyRender: () => (
                                                <div>
                                                    <Button>
                                                        <SearchIcon />
                                                    </Button>
                                                    <Button color="secondary">
                                                        <DeleteIcon />
                                                    </Button>
                                                </div>
                                            ),
                                            print: false
                                        }
                                    }
                                ]}
                                title="Pedidos"

                            />
                        </Grid>

                    </Grid>
                </Container>
            </main>
        </div >
    );
}

export default Pedidos