import React, { useEffect, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Checkbox, FormControlLabel, MenuItem, Select } from '@material-ui/core';

import AddBoxIcon from '@material-ui/icons/AddBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import NumberFormatReal from '../../components/NumberFormatReal'
import NumberFormatPercent from '../../components/NumberFormatPercent'
import SideBar from '../../components/SideBar'
import InputAutocomplete from '../../components/InputAutocomplete';

import useStyles from '../../styles/global'
import formSaleStyles from './styles'

import api from '../../service/api'
import { connect } from 'react-redux';


function CreatePedido(props) {

    const classes = useStyles()

    const styles = formSaleStyles()

    const [total, setTotal] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [name, setName] = useState('')
    const [items, setItems] = useState([{
        product: '',
        description: '',
        value: 0,
        amount: 0,
        total: 0
    }])
    const [methodOfPayment, setMethodOfPayment] = useState("")
    const [formOfPayment, setFormOfPayment] = useState("")
    const [allInstallmentsEquals, setAllInstallmentsEquals] = useState(true)
    const [installments, setInstallments] = useState([])
    const [numberOfInstallments, setNumberOfInstallments] = useState(0)
    const [statusOfPayment, setStatusOfPayment] = useState("PENDENTE")

    useEffect(() => {
        handleNumberOfInstallments()
    }, [numberOfInstallments, total])


    function addNewItem() {

        setItems([
            ...items,
            {
                product: '',
                description: '',
                value: 0,
                amount: 0,
                total: 0
            }
        ])
    }

    function removeItem(index) {

        const newListItems = items.filter((item, i) => i !== index)

        const sumTotalItems = newListItems.reduce((total, item) => total + item.total, 0)

        setTotal(sumTotalItems)
        setItems(newListItems)

    }



    function handleValueItems(index, field, value) {

        const newListItems = items.map((item, i) => {


            if (i === index) {
                item[`${field}`] = value;

                if (field === 'product') {
                    item['value'] = value.unitPrice
                }

                if (field === "amount" && item.value) {
                    item['total'] = value * item.value
                }

                if (field === "value" && item.amount) {
                    item['total'] = value * item.amount
                }


            }

            return item
        })

        const sumTotalItems = newListItems.reduce((total, item) => total + item.total, 0)

        setTotal(sumTotalItems)
        setItems(newListItems)
    }

    const handleInstallmentsEquals = () => {
        setAllInstallmentsEquals(!allInstallmentsEquals)
        handleNumberOfInstallments()
    }

    const handleValueInstallment = (index, value) => {

        let newInstallments = installments

        newInstallments[index]["value"] = value

        setInstallments(newInstallments)

    }

    const handleNumberOfInstallments = () => {

        let newInstallments = []

        for (let i = 0; i < numberOfInstallments; i++) {
            newInstallments.push({
                index: i + 1,
                value: total / numberOfInstallments,
                status: "PENDENTE"
            })
        }

        setInstallments(newInstallments)
    }

    const handleStatusOfInstallment = (index) => {

        let newInstallments = installments.map(installment => {

            if (installment.index === index) {
                if (installment.status === "PAGO") {

                    installment.status = "PEDENTE"

                } else {

                    installment.status = "PAGO"

                }

            }

            return installment
        })


        setInstallments(newInstallments)
    }

    const handleStatusOfPayment = () => {

        if (statusOfPayment === "PENDENTE") {
            setStatusOfPayment("PAGO")
        } else {
            setStatusOfPayment("PENDENTE")

        }
    }

    const getOptions = async () => {

        const { token } = props.user

        const response = await api.get('/produto', {
            headers: {
                authorization: `Bearer ${token}`
            }
        })

        return response.data
    }

    return (
        <div className={classes.root} >
            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <h1>Criando novo pedido</h1>
                    {
                        items.map((item, index) => (

                            <Grid key={index} container spacing={3} direction="row" justify="center" alignItems="flex-start" className={styles.contentItem}>
                                <Grid item xs={12} md={5} lg={5}>
                                    <InputAutocomplete
                                        fieldFilter="name"
                                        getOptions={getOptions}
                                        onChange={value => {
                                            handleValueItems(index, "product", value)
                                        }}
                                    />
                                    <FormControl className={styles.formControl}>
                                        <TextField
                                            id="description-item"
                                            label="Descrição"
                                            value={item.description}
                                            onChange={event => { handleValueItems(index, 'description', event.target.value) }}
                                            multiline
                                            spellCheck={false}
                                        />
                                    </FormControl>

                                    <FormControl className={styles.formControl}>
                                        <InputLabel id="label-status-of-request">Status</InputLabel>
                                        <Select
                                            labelId="label-status-of-request"
                                            id="status-of-request"

                                        >
                                            <MenuItem value={"PRONTO ENTREGA"}>PRONTO ENTREGA</MenuItem>
                                            <MenuItem value={"PROCESSO DE FABRICAÇÃO"}>PROCESSO DE FABRICAÇÃO</MenuItem>

                                        </Select>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={5} lg={5}>
                                    <Grid container direction="row" className={styles.unitOfMeasurement}>

                                        <FormControl className={styles.inputAmount} >
                                            <InputLabel htmlFor={`value-amount-${index}`}>Quantidade</InputLabel>
                                            <Input

                                                value={item.amount}
                                                onChange={(event) => { handleValueItems(index, 'amount', event.target.value) }}
                                                min={0}
                                                id={`value-amount-${index}`}
                                                type="number"
                                            />

                                        </FormControl>

                                        <p style={{ textAlign: "center", display: "flex", alignSelf: "flex-end", lineHeight: 0, marginLeft: 4 }}>

                                            {item.product ? item.product.unitOfMeasurement : "Na unidade de medida"}
                                        </p>

                                    </Grid>

                                    <FormControl className={styles.formControl} >
                                        <InputLabel htmlFor={`value-uni-${index}`}>Valor Unitário (R$)</InputLabel>
                                        <Input
                                            name="unitPrice"
                                            value={item.value}
                                            onChange={(event) => { handleValueItems(index, 'value', event.target.value) }}

                                            id={`value-uni-${index}`}
                                            inputComponent={NumberFormatReal}
                                        />

                                    </FormControl>

                                    <FormControl className={styles.formControl} >
                                        <InputLabel htmlFor={`value-total-${index}`}>Valor Total (R$)</InputLabel>
                                        <Input
                                            style={{ color: "#333" }}
                                            value={item.total}
                                            disabled
                                            name="totalPrice"
                                            id={`value-total-${index}`}
                                            inputComponent={NumberFormatReal}
                                        />

                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} md={2} lg={2} className={styles.contentButtonAddField} >
                                    {
                                        index === items.length - 1 ?
                                            items.length === 1 ?
                                                <Button onClick={() => addNewItem()}>
                                                    <AddBoxIcon />
                                                </Button>
                                                :
                                                <>
                                                    <Button color="secondary" onClick={() => { removeItem(index) }}>
                                                        <IndeterminateCheckBoxIcon />
                                                    </Button>
                                                    <Button onClick={() => addNewItem()}>
                                                        <AddBoxIcon />
                                                    </Button>
                                                </>
                                            :
                                            <Button color="secondary" onClick={() => { removeItem(index) }}>
                                                <IndeterminateCheckBoxIcon />
                                            </Button>
                                    }
                                </Grid>
                            </Grid>
                        ))
                    }

                    <Grid container spacing={3} className={styles.contentClient}  >
                        <Grid item xs={12} md={12} lg={12} >
                            <FormControl className={styles.formControl} style={{ width: 95 + "%" }}>
                                <InputLabel htmlFor={`client-name`}>Nome do Cliente</InputLabel>
                                <Input

                                    value={name}
                                    id={`client-name`}
                                    onChange={e => setName(e.target.value)}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} >
                            <FormControl className={styles.formControl}>
                                <TextField
                                    id="deliveryForecast"
                                    label="Data de Entrega"

                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormControl className={styles.formControl}>
                                <TextField
                                    id="address"
                                    label="Endereço de Entrega"
                                    multiline
                                    spellCheck={false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} >
                            <FormControl className={styles.formControl} >
                                <InputLabel id="label-method-of-payment">Método de Pagamento</InputLabel>
                                <Select
                                    labelId="label-method-of-payment"
                                    id="method-of-payment"
                                    value={methodOfPayment}
                                    onChange={e => { setMethodOfPayment(e.target.value) }}
                                >
                                    <MenuItem value={"DINHEIRO"}>DINHEIRO</MenuItem>
                                    <MenuItem value={"CARTÃO DE CRÉDITO"}>CARTÃO DE CRÉDITO</MenuItem>
                                    <MenuItem value={"CARTÃO DE DÉBITO"}>CARTÃO DE DÉBITO</MenuItem>
                                    <MenuItem value={"TRANSFERÊNCIA"}>TRANSFERÊNCIA</MenuItem>
                                    <MenuItem value={"CHEQUE"}>CHEQUE</MenuItem>
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} >
                            <FormControl className={styles.formControl} >
                                <InputLabel id="label-form-of-payment">Forma de Pagamento</InputLabel>
                                <Select
                                    labelId="label-form-of-payment"
                                    id="form-of-payment"
                                    value={formOfPayment}
                                    onChange={e => { setFormOfPayment(e.target.value) }}
                                >
                                    <MenuItem value={"À VISTA"}>À VISTA</MenuItem>
                                    <MenuItem value={"PARCELADO"}>PARCELADO</MenuItem>

                                </Select>

                            </FormControl>
                        </Grid>
                        {
                            formOfPayment === "À VISTA" &&
                            <>
                                <Grid item xs={12} md={2} lg={2} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end"
                                }}>
                                    <p style={statusOfPayment === "PAGO" ? { color: "green" } : { color: "orange" }}>{statusOfPayment}</p>

                                </Grid>
                                <Grid item xs={12} md={2} lg={2} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end"
                                }} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={statusOfPayment === "PAGO"}
                                                onChange={handleStatusOfPayment}
                                                name="installments-equals"
                                                color="primary"
                                            />
                                        }
                                        label="Pago"

                                    />
                                </Grid>
                            </>
                        }
                        {
                            formOfPayment === "PARCELADO" &&
                            <>
                                <Grid item xs={12} md={4} lg={4} >
                                    <FormControl className={styles.formControl} style={{ width: 85 + "%" }} >
                                        <InputLabel id="number-of-installments">Número de Parcelas</InputLabel>
                                        <Input
                                            id={"number-of-installments"}
                                            type="number"
                                            value={numberOfInstallments}
                                            onChange={e => { setNumberOfInstallments(e.target.value) }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} >


                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={allInstallmentsEquals}
                                                onChange={handleInstallmentsEquals}
                                                name="installments-equals"
                                                color="primary"
                                            />
                                        }
                                        label="Todas as parcelas iguais"
                                    />
                                    {
                                        installments.map(installment => (
                                            <Grid container spacing={3} direction="row" key={installment.index} style={{ marginTop: 8 }}>
                                                <Grid item xs={12} md={2} lg={2}>
                                                    <strong>{installment.index}ª Parcela</strong>

                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>

                                                    <FormControl className={styles.formControl} >
                                                        <InputLabel id={`label${installment.index}-value-of-installment`}>Valor da Parcela</InputLabel>
                                                        <Input
                                                            labelId={`label-${installment.index}-value-of-installment`}
                                                            id={`${installment.index}-value-of-installment`}
                                                            value={installment.value}
                                                            onChange={e => { handleValueInstallment(installment.index - 1, e.target.value) }}
                                                            name="installment"
                                                            disabled={allInstallmentsEquals}
                                                            inputComponent={NumberFormatReal}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2} lg={2}>
                                                    <p style={installment.status === "PAGO" ? { color: "green" } : { color: "orange" }}>{installment.status}</p>

                                                </Grid>
                                                <Grid item xs={12} md={2} lg={2}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={installment.status === "PAGO"}
                                                                onChange={e => handleStatusOfInstallment(installment.index)}
                                                                name={`${installment.index}-payment-status`}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Pago"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2} lg={2} >
                                                    <FormControl className={styles.formControl}>
                                                        <TextField
                                                            id={`${installment.index}-date`}
                                                            label="Data de Vencimento"

                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }


                                </Grid>
                            </>
                        }



                        <Grid item xs={12} md={12} lg={12}>
                            <FormControl className={styles.formControl} style={{ width: 95 + "%" }}>
                                <TextField
                                    id="observation"
                                    label="Observação"
                                    multiline
                                    spellCheck={false}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3} className={styles.contentTotal} direction="row" alignItems="center" justify="flex-end">
                        <Grid item xs={12} md={6} lg={4} >
                            <FormControl className={styles.formControl} >
                                <InputLabel htmlFor={`value-total`}>Valor Total do Pedido (R$)</InputLabel>
                                <Input
                                    style={{ color: "#333" }}
                                    disabled
                                    value={total}
                                    id={`value-total`}
                                    inputComponent={NumberFormatReal}
                                />

                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3} justify="flex-end">
                        <Button className={classes.buttonSuccess}>
                            Criar Pedido
                        </Button>
                    </Grid>
                </Container>
            </main>
        </div>
    )
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(CreatePedido)