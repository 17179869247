import React from 'react'
import Grid from '@material-ui/core/Grid';

import orderStyles from './styles'

export default props => {
    
    const styles = orderStyles()

    return (
        <div key={props.file.id} className={styles.contentImage}>
            <img src={`${props.file.preview}`} alt={props.file.name} width="100%" />
            <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                <p >{props.file.name} - <small className="text-muted">{props.file.readableSize}</small></p>
                
            </Grid>
        </div>
    )
}