import React from 'react'
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Dashboard from "../pages/Dashboard"

import CreateVenda from "../pages/CreateVenda"
import CreateCompra from "../pages/CreateCompra"


import Clientes from "../pages/Clientes"
import CreateCliente from "../pages/CreateCliente"

import Pedidos from "../pages/Pedidos"
import CreatePedido from "../pages/CreatePedido"

import Produtos from '../pages/Produtos'
import CreateProduto from '../pages/CreateProduto'
import ShowProduto from '../pages/ShowProduto'
import EditProduto from '../pages/EditProduto'

import Solucoes from '../pages/Solucoes'
import ShowSolucao from '../pages/ShowSolucao'
import CreateSolucao from '../pages/CreateSolucao'
import EditSolucao from '../pages/EditSolucao'

import Usuarios from '../pages/Usuarios'
import CreateUsuario from '../pages/CreateUsuario'
import ShowUsuario from '../pages/ShowUsuario'
import EditUsuario from '../pages/EditUsuario'


const AdminRoutes = (props) => {
    return (
        <Switch>
            <Route path="/" exact component={Dashboard} />

            {/* <Route path="/clientes" exact component={Clientes} /> */}

            <Route path="/usuarios" exact component={Usuarios} />
            <Route path="/usuarios/novo" exact component={CreateUsuario} />
            <Route path="/usuario/:id" exact component={ShowUsuario} />
            <Route path="/usuarios/edit/:id" exact component={EditUsuario} />

            <Route path="/pedidos" exact component={Pedidos} />
            
            
            <Route path="/pedido/novo" exact component={CreatePedido} />


            <Route path="/produtos" exact component={Produtos} />
            <Route path="/produtos/novo" exact component={CreateProduto} />
            <Route path="/produto/:id" exact component={ShowProduto} />
            <Route path="/produto/edit/:id" exact component={EditProduto} />

            <Route path="/solucoes" exact component={Solucoes} />
            <Route path="/solucao/:id" exact component={ShowSolucao} />
            <Route path="/solucoes/novo" exact component={CreateSolucao} />
            <Route path="/solucoes/edit/:id" exact component={EditSolucao} />

            <Redirect from="*" to="/" />
        </Switch>
    )
}
const FuncionarioRoutes = (props) => {
    return (
        <Switch>
            <Route path="/pedidos" exact component={Pedidos} />
            

            <Route path="/pedido/novo" exact component={CreatePedido} />

            <Route path="/produtos" exact component={Produtos} />
            <Route path="/produtos/novo" exact component={CreateProduto} />

            <Redirect from="*" to="/pedidos" />
        </Switch>
    )
}

const FuncinarioSiteRoutes = (props) => {

    return (
        <Switch>

            <Route path="/pedidos" exact component={Pedidos} />

            <Route path="/produtos" exact component={Produtos} />
            <Route path="/produtos/novo" exact component={CreateProduto} />

            <Route path="/solucoes" exact component={Solucoes} />
            <Route path="/solucao/:id" exact component={ShowSolucao} />
            <Route path="/solucoes/novo" exact component={CreateSolucao} />
            <Route path="/solucoes/edit/:id" exact component={EditSolucao} />

            <Redirect from="*" to="/ordens" />
        </Switch>
    )
}

const SiteRoutes = (props) => {
    return (
        <Switch>
            <Route path="/solucoes" exact component={Solucoes} />
            <Route path="/solucao/:id" exact component={ShowSolucao} />
            <Route path="/solucoes/novo" exact component={CreateSolucao} />
            <Route path="/solucoes/edit/:id" exact component={EditSolucao} />

            <Redirect from="*" to="/solucoes" />

        </Switch>
    )
}

function AppRoutes(props) {
    return (
        <BrowserRouter>
            <Switch>
                {

                    props.user.permission === 1 &&
                    <AdminRoutes />
                }

                {

                    (props.user.permission === 2) &&
                    <FuncinarioSiteRoutes />
                }

                {
                    props.user.permission === 3 &&
                    <FuncionarioRoutes />
                }

                {
                    props.user.permission === 4 &&
                    <SiteRoutes />
                }

            </Switch>
        </BrowserRouter>

    )
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(AppRoutes)