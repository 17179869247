import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import SideBar from '../../components/SideBar'
import ChooseImage from '../../components/ChooseImage'

import useStyles from '../../styles/global'
import api from '../../service/api';


const validationSchema = yup.object().shape({
    title: yup
        .string()
        .required("Título é obrigatório"),
    description: yup
        .string()
        .required("Descrção é obrigatória"),
})


function EditSolucao(props) {

    const classes = useStyles()

    const history = useHistory()

    const [solucao, setSolucao] = useState(null)

    useEffect(() => {

        getSolution()

    }, [])

    const getSolution = async () => {


        const { id } = props.match.params

        api
            .get(`/solucao/${id}`)
            .then(res => {
                if (res.data) {


                    setSolucao(res.data)
                    preparePhotosSolution(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    const preparePhotosSolution = (solucao) => {

        setImageSolution([
            {
                preview: `https://api.premoldadosconcreta.com.br/images/${solucao.photo}`
            }
        ])

        const photos = solucao.photos.map(photo => {
            return {
                preview: `https://api.premoldadosconcreta.com.br/images/${photo}`
            }
        })


        setImageSolutionStock([
            ...photos
        ])
    }

    const editSolution = async (values) => {

        const data = new FormData()

        const { title, description, photo, photos } = values

        data.append("title", title);
        data.append("description", description);

        if (photo[0].file) {

            data.append("photo", photo[0].file.name);
            data.append("files", photo[0].file, photo[0].file.name);

        } else {

            data.append("photo", solucao.photo);

        }

        photos.forEach(photo => {

            if (photo.file) {

                data.append("photos", photo.file.name)
                data.append("files", photo.file, photo.file.name);

            } else {

                let filename = photo.preview.split("/").reverse()[0]
                
                data.append("photos", filename)
            }
        });

        const { token } = props.user

        console.log(values)
        api({
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            },
            url: `/solucao/${solucao._id}`,
            data: data,
            method: "PUT"
        }).then(res => {

            if (res.data) {

                Swal.fire({
                    position: 'bottom-end',
                    icon: 'success',
                    title: 'Solução Atualizada!',
                    showConfirmButton: false,
                    timer: 1500
                })
                
                history.goBack()
            }

        }).catch(err => {

            console.log(err)

        })
    }

    const [imageSolution, setImageSolution] = useState([])
    const [imageSolutionStock, setImageSolutionStock] = useState([])


    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        <h1>Editando Solução</h1>
                    </Grid>
                    {
                        solucao &&
                        <Formik
                            initialValues={{
                                title: solucao.title,
                                description: solucao.description,
                                photo: '',
                                photos: []
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                editSolution(values)
                            }}
                        >
                            {({ values, handleChange, handleSubmit, errors, touched, setFieldTouched }) => {

                                values.photos = imageSolutionStock
                                values.photo = imageSolution

                                return (
                                    <Grid container className={classes.contentItem}>
                                        <Grid container spacing={3} direction="row" >
                                            <Grid item xs={12} md={6} lg={6} style={{ minHeight: 400, padding: '3rem' }}>
                                                <span><h3>Imagem da Solução</h3> ( 1 : 1 ) Quadrada</span>
                                                <ChooseImage max={1} photos={imageSolution} onChange={photos => setImageSolution(photos)} />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{ paddingTop: 48 }}>
                                                <h3>Dados da Solução</h3>
                                                <Grid item xs={12} md={12} lg={12} >

                                                    <FormControl className={classes.formControl} >
                                                        <InputLabel htmlFor={`title-of-solution`}>Título da Solução *</InputLabel>
                                                        <Input
                                                            style={{ color: "#333" }}
                                                            id={`title-of-solution`}
                                                            value={values.title}
                                                            name="title"
                                                            onChange={handleChange}
                                                            onBlur={() => setFieldTouched('title')}
                                                            spellCheck={false}

                                                        />
                                                        {
                                                            touched.title && errors.title &&
                                                            <p style={{ color: 'red' }}>{errors.title}</p>
                                                        }

                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={12}>

                                                    <FormControl className={classes.formControl} >
                                                        {/* <InputLabel htmlFor={`description-of-solution`}>Descrição da Solução</InputLabel> */}
                                                        <TextField
                                                            style={{ color: "#333" }}
                                                            label="Descrição da Solução *"
                                                            id={`description-of-solution`}
                                                            multiline
                                                            name="description"
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            onBlur={() => setFieldTouched('description')}
                                                            spellCheck={false}
                                                        />
                                                        {
                                                            touched.description && errors.description &&
                                                            <p style={{ color: 'red' }}>{errors.description}</p>
                                                        }

                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <span><h3>Fotos dessa solução que foram vendidas, instaladas ou em estoque </h3>( 3 : 2 ) Retangular</span>
                                        <Grid container spacing={3} direction="row" style={{ padding: '3rem' }} >
                                            <ChooseImage max={9} photos={imageSolutionStock} onChange={photos => setImageSolutionStock(photos)} />
                                        </Grid>
                                        <Grid container spacing={3} direction="row" justify="flex-end" style={{ padding: '3rem' }} >
                                            <Button onClick={() => { history.goBack() }} className={classes.buttonDanger} style={{ marginRight: "1rem" }}>
                                                Descartar alterações
                                            </Button>
                                            <Button className={classes.buttonSuccess} onClick={handleSubmit} style={{ marginRight: "1rem" }}>
                                                Salvar alterações
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            }
                        </Formik>
                    }
                </Container>
            </main>
        </div>
    )
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(EditSolucao)