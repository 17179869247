import React, { useEffect, useState } from 'react'
import { uniqueId } from "lodash";
import filesize from "filesize";
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';

import DropZone from '../DropZone'
import Preview from '../Preview'

import useStyles from '../../styles/global'

function ChooseImage(props) {

    const classes = useStyles()

    const [localPhotos, setLocalPhotos] = useState([])

    useEffect(() => {

        setLocalPhotos(props.photos)

    }, [props.photos])

    const handleUpload = files => {

        const file = files[0]

        const photos = props.photos

        const uploadFile = {
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),

        }

        if (props.max === 1) {

            props.onChange([uploadFile])

        } else {

            photos.push(uploadFile)

            setLocalPhotos([
                ...photos]);

            props.onChange(photos)

        }
    }

    const removePhoto = index => {

        if (props.max === 1) {

            props.onChange([])

        } else {

            const photos = props.photos.filter((item, i) => index !== i)

            setLocalPhotos(photos)
            props.onChange(photos)
        }

    }

    return (
        <>

            <Grid container direction="row">
                {
                    props.max === 1 ?
                        <Grid item xs={12} md={12} lg={12} >
                            {
                                props.photos[0] &&
                                < >
                                    <Preview file={props.photos[0]} />
                                    <Grid container direction="row" alignItems="center" justify="center" >
                                        <Button className={classes.buttonDanger} onClick={() => removePhoto(0)}>
                                            Remover
                                        </Button>
                                    </Grid>
                                </>

                            }
                        </Grid>
                        :

                        localPhotos.map((photo, index) => (

                            <Grid item xs={12} md={4} lg={4} key={index} >

                                <Preview file={photo} />
                                <Grid container direction="row" alignItems="center" justify="center" >
                                    <Button className={classes.buttonDanger} onClick={() => removePhoto(index)}>
                                        Remover
                                        </Button>

                                </Grid>

                            </Grid>
                        ))

                }

                {
                    props.photos.length >= props.max || (props.max === 1 && props.photos[0]) ?
                        null
                        :
                        <Grid item xs={12} md={props.max === 1 ? 12 : 4} lg={props.max === 1 ? 12 : 4} style={{ paddingTop: 24 }} >

                            <label >Escolha a imagem: *</label>

                            <DropZone onUpload={handleUpload} />
                        </Grid>
                }
            </Grid>

        </>
    )

}

export default ChooseImage