import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Swal from 'sweetalert2'

import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'

import localStyles from './styles'
import api from '../../service/api';

function ShowSolucoes(props) {

    const classes = useStyles()

    const styles = localStyles()

    const history = useHistory()

    const [solucao, setSolucao] = useState(null)

    useEffect(() => {

        if (solucao === null)
            getSolution()

    }, [])

    const getSolution = async () => {


        const { id } = props.match.params

        api
            .get(`/solucao/${id}`)
            .then(res => {
                if (res.data) {

                    setSolucao(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    const deleteSolution = async () => {

        const { _id } = solucao

        Swal.fire({
            title: 'Apagar',
            text: 'Essa operação será irrevesível, realmente deseja apagar este item?',
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Cancelar",
            denyButtonText: `Apagar`,
            icon: 'info'
        }).then((result) => {

            if (result.isDenied) {

                api
                    .delete(`/solucao/${_id}`)
                    .then(res => {

                        if (res.data)
                            Swal.fire({
                                position: 'bottom-end',
                                icon: 'success',
                                title: 'Solução Apagada!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        })
    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>


                    <Grid container spacing={3} direction="row" alignItems="center" justify="space-between">
                        {
                            solucao &&
                            <h1>{solucao.title}</h1>
                        }
                    </Grid>
                    <Grid container className={classes.contentItem}>
                        <Grid item xs={12} md={6} lg={6} style={{ minHeight: 400, padding: '3rem' }}>
                            <h3>Imagem da Solução</h3>
                            {
                                solucao &&
                                <img src={`https://api.premoldadosconcreta.com.br/images/${solucao.photo}`} alt={solucao.title} className={styles.imageMain} />
                            }
                        </Grid>
                        {
                            solucao &&
                            <>
                                <Grid item xs={12} md={6} lg={6} style={{ paddingTop: 48 }}>
                                    <h3>Dados da Solução</h3>
                                    <h4>Título</h4>
                                    <p>{solucao.title}</p>
                                    <h4>Descrição</h4>
                                    <p>{solucao.description}</p>
                                </Grid>
                                <h3>Fotos dessa solução que foram vendidas, instaladas ou em estoque </h3>
                                <Grid container spacing={3} direction="row" justify="center" style={{ padding: '3rem' }} >
                                    {
                                        solucao.photos &&

                                        solucao.photos.map(photo => (

                                            <img key={photo} src={`https://api.premoldadosconcreta.com.br/images/${photo}`} alt={solucao.title} className={styles.imageStock} />
                                        ))
                                    }

                                </Grid>
                                <Grid container spacing={3} direction="row" justify="flex-end" style={{ padding: '3rem' }}>
                                    <Button onClick={() => { history.goBack() }} className={classes.buttonSecondary} style={{ marginRight: "1rem" }}>
                                        Voltar
                                    </Button>
                                    <Button onClick={() => { deleteSolution() }} className={classes.buttonDanger} style={{ marginRight: "1rem" }}>
                                        Apagar
                                    </Button>
                                    <Link to={`/solucoes/edit/${solucao._id}`}>
                                        <Button className={classes.buttonWarning} style={{ marginRight: "1rem" }}>
                                            Editar
                                        </Button>
                                    </Link>
                                </Grid>
                            </>
                        }
                    </Grid>

                </Container>
            </main>
        </div>
    )
}

export default ShowSolucoes