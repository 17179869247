import React, { useState, useEffect } from 'react'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from 'formik'
import * as yup from 'yup'

import NumberFormatReal from '../../components/NumberFormatReal'
import SideBar from '../../components/SideBar'

import useStyles from '../../styles/global'

import localStyles from './styles'
import api from '../../service/api';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const validationSchema = yup.object().shape({
    name: yup
        .string()
        .required("O nome do produto é obrigatório"),
    unitOfMeasurement: yup
        .string()
        .required("A unidade de medida é obrigatória"),
    unitPrice: yup
        .string()
        .required("O preço unitário é obrigatório")
})

function EditProduto(props) {

    const classes = useStyles()

    const styles = localStyles()

    const history = useHistory()

    const [product, setProduct] = useState(null)

    useEffect(() => {
        if (product === null)
            getProduto()
    })

    const getProduto = async () => {


        const { id } = props.match.params

        const { token } = props.user

        api.get(`/produto/${id}`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {

            setProduct(res.data)

        }).catch(err => {
            console.error(err)
        })
    }

    const saveEditions = async (values) => {

        const { token } = props.user

        const { id } = props.match.params

        const data = {
            ...values,
            unitPrice: parseFloat(values.unitPrice)
        }

        api.put(`/produto/${id}`, data, {
            headers: {
                authorization: `Bearer ${token}`
            }
        }).then(res => {

            if (res.data) {
                Swal.fire({
                    position: 'bottom-end',
                    icon: 'success',
                    title: 'Produto Atualizado!',
                    showConfirmButton: false,
                    timer: 1500
                })

                history.goBack()
            }

        }).catch(err => {
            console.error(err)
        })

    }

    return (
        <div className={classes.root}>

            <SideBar />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container  >
                        <h1>Editando produto</h1>

                    </Grid>
                    {
                        product !== null ?

                            <Formik
                                initialValues={{
                                    name: product.name,
                                    unitOfMeasurement: product.unitOfMeasurement,
                                    unitPrice: product.unitPrice
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values) => {
                                    saveEditions(values)
                                }}
                            >
                                {({ values, handleChange, handleSubmit, errors, setFieldTouched, touched }) => {

                                    return (
                                        <>
                                            <Grid container className={styles.contentProduct}>
                                                <Grid item xs={12} md={12} lg={12}>

                                                    <FormControl className={styles.formControl} >
                                                        <InputLabel htmlFor={`nome-of-product`}>Nome do Produto</InputLabel>
                                                        <Input
                                                            style={{ color: "#333" }}
                                                            id={`name-of-product`}
                                                            value={values.name}
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={() => setFieldTouched('name')}
                                                        />
                                                        {
                                                            touched.name && errors.name &&
                                                            <p style={{ color: 'red' }}>{errors.name}</p>
                                                        }

                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>

                                                    <FormControl className={styles.formControl} >
                                                        <InputLabel htmlFor={`nome-of-product`}>Unidade de Medida (ex: Unidades)</InputLabel>
                                                        <Input
                                                            style={{ color: "#333" }}
                                                            id={`unit-of-measurement`}
                                                            value={values.unitOfMeasurement}
                                                            name="unitOfMeasurement"
                                                            onChange={handleChange}
                                                            onBlur={() => setFieldTouched('unitOfMeasurement')}
                                                        />
                                                        {
                                                            touched.unitOfMeasurement && errors.unitOfMeasurement &&
                                                            <p style={{ color: 'red' }}>{errors.unitOfMeasurement}</p>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={4} lg={4}>

                                                    <FormControl className={styles.formControl} >
                                                        <InputLabel htmlFor={`nome-of-product`}>Preço por {values.unitOfMeasurement ? values.unitOfMeasurement : "Unidade de Medida"}</InputLabel>
                                                        <Input
                                                            style={{ color: "#333" }}
                                                            id={`unit-price`}
                                                            value={values.unitPrice}
                                                            name="unitPrice"
                                                            onChange={handleChange}
                                                            inputComponent={NumberFormatReal}
                                                            onBlur={() => setFieldTouched('unitPrice')}
                                                        />
                                                        {
                                                            touched.unitPrice && errors.unitPrice &&
                                                            <p style={{ color: 'red' }}>{errors.unitPrice}</p>
                                                        }

                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ marginTop: 8 }} justify="flex-end" >
                                                <Button onClick={() => { history.goBack() }} className={classes.buttonDanger} style={{ marginRight: "1rem" }}>
                                                    Descartar Alterações
                                                </Button>
                                                <Button onClick={handleSubmit} className={classes.buttonSuccess} >
                                                    Salvar Alterações
                                                </Button>
                                            </Grid>
                                        </>
                                    )
                                }}

                            </Formik>
                            :
                            <CircularProgress />
                    }
                </Container>
            </main>
        </div>
    );
}

const mapStateToProps = state => ({ user: state.user })

export default connect(mapStateToProps)(EditProduto)